<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:body>
                <b-overlay :show="loader">
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                                    <b-row>
                                        <!-- <b-col lg="12" sm="12">
                                            <ValidationProvider name="Registration No" vid="registration_no" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="3"
                                                    label-for="registration_no"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                    {{ $t('teaGardenBtriService.registration_no') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="registration_no"
                                                    v-model="data.registration_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col> -->
                                        <b-col lg="12" sm="12" v-if="courseGradePoint">
                                          <ValidationProvider name="Grade Point" vid="grade_point" :rules="'required|max_value:' + courseGradePoint">
                                            <b-form-group
                                              class="row"
                                              label-cols-sm="3"
                                              label-for="grade_point"
                                              slot-scope="{ valid, errors }">
                                              <template v-slot:label>
                                                {{ $t('teaGardenPduService.grade_point') }} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-input
                                                id="grade_point"
                                                v-model="data.grade_point"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                @keypress="isNumber"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                              <small class="text-success">{{ currentLocale === 'en' ? 'Out of ' + courseGradePoint : $n(courseGradePoint) + ' এর মধ্যে' }}</small>
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <div class="row">
                                        <div class="col-sm-3"></div>
                                        <div class="col text-right">
                                            <b-button type="submit" variant="primary" class="mr-2">{{ $t('teaGardenPduService.certify') }}</b-button>
                                            &nbsp;
                                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-8')">{{ $t('globalTrans.cancel') }}</b-button>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { applicantInfoCertifyApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'

export default {
    name: 'FormLayout',
    props: ['id'],
    created () {
      if (this.id) {
        const tmp = this.getInfo()
        this.data = tmp
        this.data.grade_point = ''
        this.setCourseGradePoint(this.data.course_id)
      }
    },
    mounted () {
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.publish') : this.$t('globalTrans.save'),
            data: {
              id: 0,
              registration_no: ''
            },
            loader: false,
            courseGradePoint: 0
        }
    },
    computed: {
      pduCourseList () {
        return this.$store.state.TeaGardenService.commonObj.pduCourseList
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
        getInfo () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async register () {
            this.loader = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: true }
            result = await RestApi.putData(teaGardenServiceBaseUrl, `${applicantInfoCertifyApi}/${this.data.id}`, this.data)
            this.$store.dispatch('mutateCommonProperties', loadinState)
            if (result.success) {
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-8')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
            this.loader = false
        },
        setCourseGradePoint (id) {
          const obj = this.$store.state.TeaGardenService.commonObj.pduCourseList.find(item => item.value === parseInt(id))
          this.courseGradePoint = obj !== undefined ? obj.grade_point : 0
        },
        isNumber (evt) {
          helpers.isNumber(evt)
        }
    }
}
</script>
